<template>
  <div>
    <v-card class="px-4 py-3" flat>
      <div class="d-flex justify-space-between align-end">
        <div class="flex-1 mr-4">
          <v-tabs
            v-model="computedTabs"
            height="40px"
            show-arrows
            @change="handleChangeTab"
          >
            <v-tab
              v-for="item in tabItems"
              class="font-weight-bold"
              :href="`#${item.id}`"
              :key="item.id"
              @click="resetFilterData"
            >
              {{ item.text }}
            </v-tab>

            <v-tab
              class="font-weight-bold"
              v-for="item in savedFilters"
              :href="`#${item.id}`"
              :key="item.id"
            >
              <div class="pr-3">{{ item.filter_name }}</div>

              <v-hover v-slot:default="{ hover }">
                <v-icon
                  :color="hover ? 'red accent-2' : null"
                  right
                  size="16px"
                  @click="handleDeleteSavedFilter(item)"
                >
                  mdi-close
                </v-icon>
              </v-hover>
            </v-tab>

            <v-tab
              v-if="isResultTabVisible"
              class="font-weight-bold"
              href="#result"
            >
              Kết quả tìm kiếm
            </v-tab>
          </v-tabs>
        </div>

        <div>
          <v-checkbox
            v-model="selectedAssignedMe"
            class="mt-0 mb-2"
            label="Phụ trách bởi tôi"
            hide-details
          ></v-checkbox>
        </div>
      </div>

      <v-divider class="mb-3"></v-divider>

      <v-row no-gutters>
        <v-col class="pr-lg-5" cols="12" lg="4" xl="6">
          <tp-search-option
            :default-option="searchType"
            :options="searchOptions"
            outlined
            :value="searchKey"
            @search="updateSearchKey($event)"
          ></tp-search-option>
        </v-col>

        <v-col class="mt-3 mt-lg-0" cols="12" lg="8" xl="6">
          <div class="d-flex align-center">
            <select-filter
              v-model="selectedBranches"
              custom-class="order-filter-item text-body-1"
              custom-placeholder="Chi nhánh phụ trách"
              :items="branches"
            ></select-filter>

            <select-delivery-date
              v-model="selectedDeliveryDate"
              class="flex-1 custom-select-delivery-date text-body-1"
              placeholder="Ngày hẹn giao"
              @onSelectShortcut="handleSelectShortcut"
            ></select-delivery-date>

            <select-order-status
              :paymentStatus.sync="selectedPaymentStatus"
              :shippingStatus.sync="selectedShippingStatus"
              :orderStatus.sync="selectedOrderStatus"
              custom-class="rounded-none text-body-1"
              custom-placeholder="Trạng thái"
            ></select-order-status>

            <v-badge
              bordered
              class="flex-1"
              :content="quantityOfSelectedInDrawer"
              :value="quantityOfSelectedInDrawer"
              overlap
            >
              <tp-text-field
                append-icon="mdi-menu-right"
                class="order-more-filter-button"
                hide-details
                placeholder="Bộ lọc khác"
                readonly
                @click="handleOpenDrawer"
              ></tp-text-field>
            </v-badge>

            <button-save-filter
              :disabled="!isResultTabVisible"
            ></button-save-filter>
          </div>
        </v-col>
      </v-row>
    </v-card>
  </div>
</template>

<script>
import moment from "moment";
import { ARRAY_LIST_MODEL_SITES, LIST_MODEL_SITES } from "@/core/constant";
import { LIST_ORDER_CHANNELS, LIST_STATUS_ORDER } from "../../../constant";
import ButtonSaveFilter from "./ButtonSaveFilter";
import SelectFilter from "./SelectFilter";
import SelectOrderStatus from "./SelectOrderStatus";
import SelectDeliveryDate from "./SelectDeliveryDate";
import { isEqual } from "@thinkvn/utils";

export default {
  components: {
    ButtonSaveFilter,
    SelectDeliveryDate,
    SelectFilter,
    SelectOrderStatus
  },

  props: {
    cancelStatus: {
      type: Array
    },
    dateFilter: {
      type: Object
    },
    employeesFilter: {
      type: Array
    },
    siteFilter: {
      type: String
    },
    orderStatus: {
      type: Array
    },
    /**
     * TODO: new props below, old props above will be removed if they are not used
     */
    searchKey: {
      type: String
    },
    searchType: {
      type: Number
    },
    searchOptions: {
      type: Array
    },
    tabs: {
      type: String
    },
    assignedMe: {
      type: Boolean
    },
    filterBranches: {
      type: Array,
      default: () => []
    },
    deliveryDates: {
      type: Array,
      default: () => []
    },
    filterOrderStatus: {
      type: Array,
      default: () => []
    },
    paymentStatus: {
      type: Array,
      default: () => []
    },
    shippingStatus: {
      type: Array
    },
    quantityOfSelectedInDrawer: {
      type: Number
    }
  },

  data() {
    return {
      isResultTabVisible: false,
      orderChannelList: LIST_ORDER_CHANNELS,
      orderStatusList: LIST_STATUS_ORDER,
      tabItems: [
        {
          id: "all",
          text: "Tất cả đơn hàng"
        },
        {
          id: "in_transaction",
          text: "Đang giao dịch"
        }
      ],

      ARRAY_LIST_MODEL_SITES,
      LIST_MODEL_SITES,
      isDrawerVisible: null,
      tab: null,
      defaultFilterData: {
        searchKey: undefined,
        searchType: 0,
        branches: [],
        deliveryDates: [],
        orderStatus: [],
        assignedMe: false,
        paymentStatus: [],
        shippingStatus: [],
        orderChannels: [],
        sites: [],
        employees: [],
        employeesCloseASale: [],
        savedSearchId: 0,
        deliveryDatesType: "",
        createdDate: [],
        recordedDate: []
      }
    };
  },

  computed: {
    branches() {
      return this.$store.getters["BRANCH/allSaleBranches"];
    },

    employees() {
      return this.$store.getters["EMPLOYEE/allEmployees"];
    },

    savedFilters() {
      return this.$store.getters["ORDER/savedFilter"];
    },

    computedIsResultTabVisible: {
      get() {
        for (let key in this.$route.query) {
          if (key !== "tabs" && key !== "limit" && key !== "page") {
            if (this.$route.query[key] !== undefined) {
              return true;
            }
          }
        }
        return false;
      },

      set(value) {
        this.isResultTabVisible = value;
      }
    },

    orderStatusRequest() {
      return this.$store.getters["ORDER/statusRequest"];
    },

    selectedCancelStatus: {
      get() {
        return this.cancelStatus;
      },
      set(val) {
        this.$emit("updateFilterProperty", {
          filterProperty: "cancelStatus",
          value: val
        });
      }
    },

    selectedEmployees: {
      get() {
        return this.employeesFilter;
      },
      set(val) {
        this.$emit("updateFilterProperty", {
          filterProperty: "employeesFilter",
          value: val
        });
      }
    },

    selectedSite: {
      get() {
        return this.siteFilter;
      },
      set(val) {
        this.$emit("updateFilterProperty", {
          filterProperty: "siteFilter",
          value: val
        });
      }
    },

    selectedOrderStatus: {
      get() {
        return this.orderStatus;
      },
      set(val) {
        this.$emit("updateFilterProperty", {
          filterProperty: "orderStatus",
          value: val
        });
      }
    },

    computedTabs: {
      get() {
        return this.tabs;
      },
      set(val) {
        this.$emit("updateFilterProperty", {
          filterProperty: "tabs",
          value: val
        });
      }
    },

    selectedAssignedMe: {
      get() {
        return this.assignedMe;
      },
      set(val) {
        this.$emit("updateFilterProperty", {
          filterProperty: "assignedMe",
          value: val
        });
      }
    },

    selectedBranches: {
      get() {
        return this.filterBranches;
      },
      set(val) {
        this.$emit("updateFilterProperty", {
          filterProperty: "branches",
          value: val
        });
      }
    },

    selectedDeliveryDate: {
      get() {
        return this.deliveryDates;
      },
      set(val) {
        this.$emit("updateFilterProperty", {
          filterProperty: "deliveryDates",
          value: val
        });
      }
    },

    selectedPaymentStatus: {
      get() {
        return this.paymentStatus;
      },
      set(val) {
        this.$emit("updateFilterProperty", {
          filterProperty: "paymentStatus",
          value: val
        });
      }
    },

    selectedShippingStatus: {
      get() {
        return this.shippingStatus;
      },
      set(val) {
        this.$emit("updateFilterProperty", {
          filterProperty: "shippingStatus",
          value: val
        });
      }
    }
  },

  created() {
    this.$store.dispatch("EMPLOYEE/getAllEmployees");
    this.$store.dispatch("BRANCH/getAllBranches");

    // const query = this.$route.query;

    // if (query.tabs === "result") {
    //   this.isResultTabVisible = true;
    // }
  },

  methods: {
    updateDateFilter(val) {
      this.$emit("updateFilterProperty", {
        filterProperty: "dateFilter",
        value: val
      });
    },

    getDateRangeOfShortcut(value) {
      const ranges = {
        TODAY: [
          moment()
            .startOf("day")
            .format("YYYY-MM-DDTHH:mm"),
          moment()
            .endOf("day")
            .format("YYYY-MM-DDTHH:mm")
        ],
        TOMORROW: [
          moment()
            .add(1, "day")
            .startOf("day")
            .format("YYYY-MM-DDTHH:mm"),
          moment()
            .add(1, "day")
            .endOf("day")
            .format("YYYY-MM-DDTHH:mm")
        ],
        COMMING_SOON: [
          moment()
            .subtract(2, "hours")
            .format("YYYY-MM-DDTHH:mm"),
          moment().format("YYYY-MM-DDTHH:mm")
        ],
        OUT_DATE: [
          moment().format("YYYY-MM-DDTHH:mm"),
          moment().format("YYYY-MM-DDTHH:mm")
        ]
      };

      return ranges[value] || [];
    },

    handleChangeTab(event) {
      const selectedSaveFilter = this.savedFilters.find(
        saveFilter => saveFilter.id === parseInt(event)
      );

      if (!selectedSaveFilter) return;

      const { id, filter_data } = selectedSaveFilter;

      const newFilterData = {
        ...this.defaultFilterData,
        ...filter_data,
        savedSearchId: id
      };

      if (filter_data.deliveryDatesType) {
        newFilterData.deliveryDates = this.getDateRangeOfShortcut(
          filter_data.deliveryDatesType
        );
      } else {
        newFilterData.deliveryDates = [
          filter_data.delivery_date_from,
          filter_data.delivery_date_to
        ];
      }

      for (let item in newFilterData) {
        this.$emit("updateFilterProperty", {
          filterProperty: item,
          value: newFilterData[item]
        });
      }
    },

    handleOpenDrawer() {
      this.$emit("openDrawerMoreFilter");
    },

    handleDeleteSavedFilter({ id, filter_name }) {
      this.$modal.show({
        name: "modal-alert",
        payload: {
          title: `<span>Xóa bộ lọc ${filter_name}?</span>`,
          message:
            "Hành động này sẽ không thể hoàn tác. Bạn có chắc chắn muốn tiếp tục?",
          confirmBtn: {
            color: "red accent-2",
            text: "Xóa",
            onClickHandler: async () => {
              await this.$store.dispatch("ORDER/deleteFilter", id);
              // update data in UI
              await this.$store.dispatch("ORDER/getFilters");

              this.resetFilterData();

              this.$emit("updateFilterProperty", {
                filterProperty: "tabs",
                value: "all"
              });

              this.$toast.show({
                name: "toast-action-alert",
                payload: {
                  message: `Xóa thành công bộ lọc ${filter_name}`
                }
              });
            }
          }
        }
      });
    },

    updateSearchKey(val) {
      this.$emit("updateSearchKey", val);
    },

    handleSelectShortcut(val) {
      this.$emit("updateFilterProperty", {
        filterProperty: "deliveryDatesType",
        value: val
      });
    },

    resetFilterData() {
      for (let item in this.defaultFilterData) {
        this.$emit("updateFilterProperty", {
          filterProperty: item,
          value: this.defaultFilterData[item]
        });
      }
    }
  },

  watch: {
    "$route.query": {
      handler(newVal) {
        let isFieldsUndefined = true;
        for (let key in newVal) {
          if (key === "savedSearchId" || newVal.savedSearchId !== undefined) {
            this.isResultTabVisible = false;
            isFieldsUndefined = false;
            break;
          } else if (
            key !== "tabs" &&
            key !== "itemPerPage" &&
            key !== "currentPage"
          ) {
            if (newVal[key] !== undefined) {
              this.isResultTabVisible = true;
              isFieldsUndefined = false;
              break;
            }
          }
        }

        if (
          isFieldsUndefined &&
          (newVal.tabs === undefined || newVal.tabs !== "in_transaction")
        ) {
          this.isResultTabVisible = false;
        }

        if (newVal.savedSearchId) {
          const query = this.$route.query;

          const filteredQuery = {};

          for (let item in query) {
            if (item === "deliveryDatesType" && query[item]) {
              // If deliveryDatesType exists and has a value, only keep it and remove delivery_date_from and delivery_date_to
              filteredQuery[item] = query[item];
              delete filteredQuery["delivery_date_from"];
              delete filteredQuery["delivery_date_to"];
            } else if (
              item !== "itemPerPage" &&
              item !== "currentPage" &&
              item !== "tabs" &&
              item !== "savedSearchId"
            ) {
              // Keep all other query parameters except for itemPerPage, currentPage, tabs, and savedSearchId
              filteredQuery[item] = query[item];
            }
          }

          const { filter_data } = this.savedFilters.find(
            saveFilter => saveFilter.id === parseInt(newVal.savedSearchId)
          );

          if (!isEqual(filteredQuery, filter_data)) {
            this.isResultTabVisible = true;
          }
        }
      },
      immediate: true
    },

    isResultTabVisible(value) {
      if (value) {
        this.$emit("updateFilterProperty", {
          filterProperty: "tabs",
          value: "result"
        });

        this.$emit("updateFilterProperty", {
          filterProperty: "savedSearchId",
          value: 0
        });
      }
    }
  }
};
</script>

<style lang="scss">
.order-filter-item {
  border-radius: 0;
  margin-right: -1px !important;
  flex: 1;

  &:first-child {
    border-radius: 8px 0 0 8px !important;
  }
}

.order-more-filter-button {
  border-radius: 0 8px 8px 0 !important;
  flex: 1;
}

.rounded-none {
  border-radius: 0;
}

.custom-order-date-picker {
  flex: 1;
  .mx-input {
    height: 40px;
    border-radius: 0;
    border: 1px solid #9e9e9e;
  }
}

.order-close-tab-button {
  position: absolute;
  top: 50%;
  right: 8px;
  transform: translate(0, -50%);
}

.flex-1 {
  flex: 1;
}
.custom-select-delivery-date {
  .mx-input-wrapper {
    input {
      height: 40px;
      border: 1px solid #9e9e9e;
      border-radius: 0;
      &::placeholder {
        color: #9e9e9e;
      }
    }
  }
}
</style>
